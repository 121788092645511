@import "~@ng-select/ng-select/themes/default.theme.css";

body {
	font-family: Lato;
	overflow: hidden !important;
	position: relative;
	padding: 0;
	margin: 0;
}

:focus:not(:focus-visible) {
	outline: none
}

.hidden {
	display: none !important;
}

.no-data-message {
	text-align: center;
	margin: 70px auto;
	font-weight: 300;
	font-size: 1.2em;
}

.h1, h1 {
	font-size: 39px;
}

.flex {
	display: flex;
}

.flex>* {
	flex: 1 0 auto;
	display: inline-flex;
	/* align-items: center; */
}

/* Client Listing Page */

.filter-options-container>span {
	height: 40px;
	display: inline-block;
	line-height: 40px;
	vertical-align: top;
	margin-top: 5px;
}

.filter-options-container ng-select .ng-select-container {
	border: 0;
	height: 40px;
}

.filter-options-container ng-select:focus-visible {
	outline: -webkit-focus-ring-color auto 1px;
}

.filter-options-container .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
	box-shadow: none;
	outline: -webkit-focus-ring-color auto 1px;
}

/* Loading Indicator Styles */

@keyframes rotateLoader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loading {
	position: fixed;
	min-height: 60px;
	transition: all .4s ease;
	height: 100%;
	width: 100%;
}

.loading.in:before {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: none;
	border: 1px solid transparent;
	content: "";
	position: absolute;
	z-index: 1001;
	transition: .4s ease all;
}

.loading.in:after, .loading.in:before {
	content: "";
	position: absolute;
	transition: all .4s ease
}

.loading.in:after {
	left: 50%;
	top: 50%;
	margin-left: -22.5px;
	margin-top: -22.5px;
	height: 45px;
	width: 45px;
	border: 5px solid #1589ee;
	border-top: 5px solid #3c3cb4;
	border-radius: 50%;
	animation: rotateLoader 1s infinite linear;
	content: "";
	position: absolute;
	z-index: 1002;
	transition: .4s ease all;
}

/* Global - Alerts */

.overlay-container {
	position: fixed;
	top: 0;
	right: 0;
	/**
	width: calc(100% - 80px);
	**/
	width: 100%;
	z-index: 99999999999;
}

.overlay-container .toast-container {
	width: 100%;
	pointer-events: all;
}

.overlay-container .toast-container .alert {
	border-radius: 0 !important;
	border: none;
	height: 70px;
	line-height: 70px;
	padding: 0;
	margin: 0 0 5px 0;
	display: block;
}

.overlay-container .toast-container .alert .toast-close-button {
	float: right;
	height: 30px;
	line-height: 30px;
	border: 0;
	outline: none;
	border-radius: 50%;
	width: 30px;
	text-align: center;
	box-shadow: none;
	margin: 20px 10px 20px 10px;
	transition: none;
	padding: 0;
}

.overlay-container .toast-container .alert .toast-close-button:hover {
	cursor: pointer;
}

.overlay-container .toast-container .alert .toast-message {
	padding: 0 13px;
	border-radius: 3px;
	font-weight: bold;
	font-size: .85em;
	height: 70px;
	line-height: 70px;
}

.overlay-container .toast-container .alert .toast-progress {
	position: relative;
	left: 0;
	bottom: 4px;
	height: 4px;
	opacity: 1;
	margin-bottom: 10px;
}

.main-alerts {
	top: 0;
}

.alert-info {
	background-color: hsl(203, 100%, 40%);
	border-color: hsl(203, 100%, 20%);
	color: white;
}

.alert-info .toast-close-button {
	background-color: hsl(203, 100%, 20%);
}

.alert-info .toast-progress {
	background-color: hsl(203, 100%, 20%);
}

.alert-success {
	background-color: hsl(145, 100%, 40%);
	border-color: hsl(145, 100%, 20%);
	color: black;
}

.alert-success .toast-close-button {
	background-color: hsl(145, 100%, 20%);
}

.alert-success .toast-progress {
	background-color: hsl(145, 100%, 20%);
}

.alert-warning {
	background-color: hsl(52, 100%, 45%);
	border-color: hsl(52, 100%, 25%);
	color: black;
}

.alert-warning .toast-close-button {
	background-color: hsl(52, 100%, 25%);
}

.alert-warning .toast-progress {
	background-color: hsl(52, 100%, 25%);
}

.alert-danger {
	background-color: hsl(14, 100%, 40%);
	border-color: hsl(14, 100%, 20%);
	color: black;
}

.alert-danger .toast-close-button {
	background-color: hsl(14, 100%, 20%);
}

.alert-danger .toast-progress {
	background-color: hsl(14, 100%, 20%);
}

.alert .fas, .alert .far {
	padding: 0 3px 0 15px;
}

/* Global - Btn */

button {
	background-color: #007FCE;
	color: white;
	border: none;
	border-radius: 4px;
}

button:hover {
	filter: brightness(92%);
	cursor: pointer;
}

button:focus {
	box-shadow: none !important;
}

input {
	background-color: white;
	border: none;
	border-radius: 4px;
	box-shadow: none;
}

input[type=number] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.switch {
	position: relative;
	display: inline-block;
}

.switch-input {
	display: none;
}

.switch-label {
	display: block;
	text-indent: 0%;
	clip: rect(0 0 0 0);
	color: transparent;
	user-select: none;
}

.switch-label::before, .switch-label::after {
	content: "";
	display: block;
	position: absolute;
	cursor: pointer;
}

.switch-label::before {
	width: 48px;
	height: 100%;
	background-color: #ccc;
	box-shadow: none;
	border-radius: 9999em;
	transition: background-color 0.25s ease;
}

.switch-label::after {
	top: 1px;
	left: 1px;
	width: 23px;
	height: 22px;
	border-radius: 50%;
	background-color: white;
	box-shadow: none;
	transition: left 0.25s ease;
}

.switch-input:checked+.switch-label::before {
	background-color: #007FCE;
}

.switch-input:checked+.switch-label::after {
	left: 24px;
}

.ng-select .ng-select-container {
	z-index: 1;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
	max-height: none !important;
}